const customTheme = {
  common: {
    borderRadius: "0.375rem",
    primaryColor: "#8b5cf6",
    primaryColorHover: "#7c3aed",
    primaryColorPressed: "#6d28d9",
    primaryColorSuppl: "#a78bfa",
  },

  Menu: {
    itemIconColorCollapsed: "#64748b", // Primary color without transparency
    itemColorActiveCollapsed: "rgba(139, 92, 246, 1.0)", // Primary color without transparency
    itemColorHover: "rgba(139, 92, 246, 0.1)", // Primary color with transparency
  },

  DatePicker: {
    itemBorderRadius: "0.375rem",
  },

  Tag: {
    borderRadius: "0.375rem",
  },
};

export default customTheme;
