import appsModule from "./modules/apps/index";

import { createStore } from "vuex";
import { userApi } from "@/api/user";
import { startFlowStages } from "@/config/model";
import { authApi } from "@/api/auth";

const defaultState = () => {
  return {
    poweredUp: false,
    user: null,

    start: {
      email: null,
      step: startFlowStages.emailPrompt,
      firstName: null,
      redirectAfterLogin: null
    },

    workspaces: {
      create: false
    },

    invite: {
      show: false,
      token: null,
      details: null,
      expired: false
    },

    social: {
      firstName: null,
      lastName: null
    },

    redirect: {
      to: null,
      message: null,
      delay: 1000
    },

    message: null,
    error: null,

    awaitingRemote: false
  };
};

export default createStore({
  state() {
    return defaultState();
  },

  getters: {
    error(state) {
      return state.error;
    },

    message(state) {
      return state.message;
    },

    step(state) {
      return state.start.step;
    },

    user(state) {
      return state.user;
    },

    social(state) {
      return state.social;
    },

    firstName(state) {
      return state.user.profile.first_name;
    },

    fullName(state) {
      return `${state.user.profile.first_name} ${state.user.profile.last_name}`;
    },

    awaitingRemote(state) {
      return state.awaitingRemote;
    },

    redirectUrl(state) {
      return state.redirect.url;
    },

    redirect(state) {
      return state.redirect;
    }
  },

  mutations: {
    user(state, user) {
      state.user = user;
    },

    error(state, e) {
      console.log(`Unexpected error from server: ${e}`);
      state.error = e;
    },

    startEmail(state, email) {
      state.start.email = email;
    },

    startStep(state, step) {
      if (!Object.values(startFlowStages).includes(step)) {
        state.error = new Error(`Unknown flow step: ${step}`);
        return;
      }

      state.start.step = step;
    },

    social(state, payload) {
      state.social = {
        ...payload
      };
    },

    welcomeUser(state, name) {
      state.start.firstName = name;
      state.start.step = startFlowStages.welcomeUser;
    },

    createWorkspace(state, enable) {
      state.workspaces.create = enable;
    },

    awaitingRemote(state) {
      state.awaitingRemote = !state.awaitingRemote;
    },

    clearState(state) {
      Object.assign(state, defaultState());
    },

    redirectAfterLogin(state, url) {
      state.start.redirectAfterLogin = url;
    },

    inviteToken(state, token) {
      state.invite.show = true;
      state.invite.token = token;
    },

    inviteDetails(state, response) {
      if (!response.status) {
        state.invite.expired = true;
      } else {
        state.invite.details = response;
      }
    },

    authWithInvite(state, response) {
      state.invite.token = null;

      if (!response.status) {
        state.invite.expired = true;
        return;
      }

      state.start.step = startFlowStages.completeSignup;
      state.invite.show = false;
    },

    acceptInvite(state, response) {
      if (!response.status) {
        state.invite.expired = true;
        return;
      }

      state.redirect = {
        url: new URL(`https://${response.redirect_to}.adept.tech/`),
        message:
          "Invitation accepted successfully! Redirecting you to the new workspace...",
        delay: 2000
      };
    },

    cleanInvite(state) {
      state.invite.token = null;
      state.invite.details = null;
      state.invite.show = false;
    },

    redirect(state, redirect) {
      state.redirect = redirect;

      if (!redirect.delay) {
        state.redirect.delay = 1000;
      }
    },

    message(state, payload) {
      state.message = payload;
    },

    poweredUp(state) {
      state.poweredUp = true;
    }
  },

  actions: {
    async fetchUser(context) {
      context.commit("awaitingRemote");

      try {
        const user = await userApi.me();
        const redirectTo = context.state.start.redirectAfterLogin;

        let destination = null;

        if (user !== null) {
          const workspaces = Object.keys(user.workspaces);

          if (redirectTo !== null) {
            const url = new URL(redirectTo);
            const requestedWorkspace = url.hostname.split(".")[0];

            if (workspaces.includes(requestedWorkspace)) {
              destination = url;
            }
          } else if (context.state.poweredUp && workspaces.length === 1) {
            destination = new URL(`https://${workspaces[0]}.adept.tech/`);
          }

          if (destination !== null) {
            context.commit("redirect", {
              url: destination,
              message:
                "Successfully logged in! Redirecting you back to your workspace..."
            });
          }
        }

        context.commit("user", user);
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async fetchTokens(context) {
      context.commit("awaitingRemote");

      try {
        return await userApi.tokens();
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async updateProfile(context, payload) {
      try {
        const userUpdateResponse = await userApi.updateProfile(payload);
        if (userUpdateResponse) {
          await context.dispatch("fetchUser");
          return true;
        }
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async createToken(context, payload) {
      try {
        const userUpdateResponse = await userApi.createToken(payload);
        return userUpdateResponse.data.access_token;
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async removeToken(context, payload) {
      try {
        const userUpdateResponse = await userApi.removeToken(payload);
        if (userUpdateResponse && userUpdateResponse.status) {
          return { status: true };
        }
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async fetchInvite(context) {
      context.commit("awaitingRemote");

      const token = context.state.invite.token;

      try {
        if (context.state.user === null) {
          const authWithInvite = await authApi.invite(token);

          if (authWithInvite.signup) {
            context.commit("authWithInvite", authWithInvite);
            return;
          }
        }

        await context.dispatch("fetchUser");

        const inviteDetails = await userApi.inviteDetails(token);
        context.commit("inviteDetails", inviteDetails);
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async acceptInvite(context) {
      context.commit("awaitingRemote");

      const token = context.state.invite.token;

      try {
        const acceptInvite = await userApi.acceptInvite(token);
        context.commit("acceptInvite", acceptInvite);
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async completeSignup(context, userFormValues) {
      context.commit("awaitingRemote");

      try {
        const register = await authApi.completeSignup(
          userFormValues.firstName,
          userFormValues.lastName,
          userFormValues.companyName
        );

        if (register.status) {
          if (register.redirect_to) {
            context.commit("redirect", {
              url: `https://${register.redirect_to}.adept.tech/`,
              message:
                "You've sign up successfully! Redirecting you to the invited workspace..."
            });
          } else {
            context.commit("welcomeUser", userFormValues.firstName);
          }
        } else {
          context.commit("startStep", startFlowStages.error);
        }
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
      }
    },

    async logout(context) {
      context.commit("awaitingRemote");

      try {
        await userApi.logout();
      } catch (e) {
        context.commit("error", e);
      } finally {
        context.commit("awaitingRemote");
        context.commit("clearState");
      }
    }
  },

  modules: {
    apps: appsModule
  }
});
