import { createApp } from "vue";
import GoogleSignInPlugin from "vue3-google-signin";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import naive from "naive-ui";

const app = createApp(App);

app.use(GoogleSignInPlugin, {
  clientId: "717501384852-9j3uglcvtvhm0ortp06vepju1kagjjv1.apps.googleusercontent.com"
});

app
  .use(naive)
  .use(store)
  .use(router);

const meta = document.createElement("meta");
meta.name = "naive-ui-style";
document.head.appendChild(meta);

app.mount("#app");
